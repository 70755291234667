import {
  query,
  safeError,
  mutation,
  callWithPagination,
  objToQueryArgs,
} from "./graphqlClient";
import {
  SalonState,
  Salon,
  Hairdresser,
  SalonPayload,
  Invoice,
  Package,
  Price,
  BroadcastList,
  PartnerRef,
} from "../reducers/salon";
import { getFromCache } from "./cache";
import { hasSalonOption } from "./salonOption";
import { PackagesPricesForm } from "components/package/PackagesPricesForm/PackagesPricesForm";

const getSalonCache = () => {
  return getFromCache("salon");
};
export interface AuthLogin {
  username: string;
  password: string;
}

const addressFields = `
  address,
  city,
  zipCode,
`.replace(/\s+/g, "");

export const packageFields = `
  id,
  category{
    id,
    name,
    key,
    isSearchable,
    slugs{
      locale,
      slug
    },
    image,
    gender
  },
  name,
  description,
  status,
  prices{
    variant{
      id,
      name,
      key
    },
    duration,
    price,
    currency,
    packageNumber,
    partnerRefs{
        name,
        id,
    },
    stringId,
  },
  allowPromo,
  partnerRefs{
      name,
      id,
  },
`.replace(/\s+/g, "");

const hairdresserFields = `
  id,
  stringId,
  firstName,
  lastName,
  image,
  color,
  packageIds,
  status,
  description,
  gender,
  partnerRefs{
    name,
    id,
  },
  serviceIds,
  imageUrl,
`.replace(/\s+/g, "");

const accessFields = `
  type,
  name,
`.replace(/\s+/g, "");

const geoLocationFields = `
  lat,
  lng,
`.replace(/\s+/g, "");

const broadcastListsFields = `
  id,
  salon{id},
  csv,
  bill,
  client,
  report,
  hairdresser,
`.replace(/\s+/g, "");

const salonFields = `
  id,
  status,
  slug,
  name,
  description,
  email,
  phone,
  mobile,
  logo,
  background,
  backgrounds,
  adwordsCampaignId,
  companyName,
  billingFirstName,
  billingLastName,
  siret,
  iban,
  tvaNumber,
  billingNationality,
  billingCountry,
  recommendation,
  carouselImages,
  thirdPartyPaymentId,
  access{${accessFields}},
  address{${addressFields}},
  billingAddress{${addressFields}},
  geoLocation{${geoLocationFields}},
  hairdressers{${hairdresserFields}},
  packages{${packageFields}},
  partnerRefs{
    name,
    id,
  },
  allowPaymentInSalon,
  isOneCatalog,
`.replace(/\s+/g, "");

const capabilitiesFields = `
  id,
  status,
  requirements{
    current_deadline,
    currently_due,
    disabled_reason,
    eventually_due,
    past_due,
    pending_verification
  }
`.replace(/\s+/g, "");

const fetchSalonsByAuthId = async (authId: number) => {
  const res = await query(
    `salons(authIds: ${authId},limit:-1){${salonFields}}`
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(buildSalonPayload(body.data.salons))
    : Promise.reject(safeError(body));
};

const fetchStripeCapabilitiesRequest = async (id: number) => {
  const res = await query(
    `stripeCapabilities(id:${id}){${capabilitiesFields}}`
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.stripeCapabilities)
    : Promise.reject(safeError(body));
};

const generateStripeForm = async (id: number) => {
  const res = await query(`stripeAccountFormLink(id:${id}){url}`);
  const body = await res.json();
  return res.ok
    ? Promise.resolve(body.data.stripeAccountFormLink.url)
    : Promise.reject(safeError(body));
};

const fetchSalonById = async (id: number) => {
  const res = await query(`salon(id: ${id}){${salonFields}}`);
  const body = await res.json();
  return res.ok
    ? Promise.resolve(buildSalonPayload([body.data.salon]))
    : Promise.reject(safeError(body));
};

const fetchSalons = async ({
  authId,
  ids,
}: {
  authId?: number;
  ids?: Array<number>;
}) => {
  if (authId) {
    return fetchSalonsByAuthId(authId);
  }
  if (ids) {
    return Promise.all(ids.map((id) => fetchSalonById(id))).then(
      (salonPayloadsArray) => {
        const salons = salonPayloadsArray
          .map((salonPayload) => Object.values(salonPayload.salonById))
          .flat();
        const salonById = salons.reduce(
          (salonById, salon) => ({
            ...salonById,
            [salon.id]: salon,
          }),
          {}
        );
        return { salonById };
      }
    );
  } else {
    return Promise.reject(
      "Erreur: Informations manquantes sur l'établissement"
    );
  }
};

const fetchAllSalonsBasicInfos = callWithPagination(
  () => async (offset: number, limit: number) => {
    const args = objToQueryArgs({
      limit,
      offset,
    });
    const res = await query(`salons(${args}){id,name,status}`);
    const body = await res.json();
    return res.ok
      ? Promise.resolve(body.data.salons)
      : Promise.reject(safeError(body));
  },
  undefined,
  100
);

const fetchBroadcastListsBySalonIds = async (salonIds: Array<number>) => {
  const broadcastListsBySalonIds = salonIds.reduce<
    Promise<Record<number, { broadcastList?: BroadcastList; error?: string }>>
  >(async (broadcastListsBySalonIdsPromise, salonId) => {
    const broadcastListsBySalonIds = await broadcastListsBySalonIdsPromise;
    const res = await query(
      `broadcastLists(salonId: ${salonId}){${broadcastListsFields}}`
    );
    const body = await res.json();
    if (res.ok) {
      return {
        ...broadcastListsBySalonIds,
        [salonId]: {
          broadcastList: body.data.broadcastLists[0],
        },
      };
    }
    return {
      ...broadcastListsBySalonIds,
      [salonId]: {
        error: safeError(body),
      },
    };
  }, Promise.resolve({}) as Promise<Record<number, { broadcastList?: BroadcastList; error?: string }>>);
  return broadcastListsBySalonIds;
};

const updateSalonById = async (salonId: number, salonInfos: Salon) => {
  const res = await mutation(
    "updateSalon",
    {
      id: {
        type: "Int",
        value: salonId,
      },
      // name: {
      //   type: "String",
      //   value: salonInfos.name
      // },
      // description: {
      //   type: "String",
      //   value: salonInfos.description
      // },
      allowPaymentInSalon: {
        type: "Boolean",
        value: salonInfos.allowPaymentInSalon,
      },
      phone: {
        type: "String",
        value: salonInfos.phone,
      },
      mobile: {
        type: "String",
        value: salonInfos.mobile,
      },
      // address: {
      //   type: "AddressInput",
      //   value: salonInfos.address
      // },
      // geoLocation: {
      //   type: "GeoLocationInput",
      //   value: salonInfos.geoLocation
      // },
      // recommendation: {
      //   type: "String",
      //   value: salonInfos.recommendation
      // },
      access: {
        type: "[AccessInput]",
        value: salonInfos.access.length > 0 ? salonInfos.access : null,
      },
      partnerRefs: {
        type: "[PartnerRefInput]",
        value: salonInfos.partnerRefs,
      },
    },
    `id,
    name,
    description,
    phone,
    mobile,
    address{${addressFields}},
    geoLocation{${geoLocationFields}},
    recommendation,
    access{${accessFields}},
    backgrounds,
    carouselImages,
    partnerRefs{name,id},
    allowPaymentInSalon`
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve<Salon>(body.data.updateSalon)
    : Promise.reject(safeError(body));
};

const updateBroadcastList = async (broadcastListInfos: BroadcastList) => {
  const res = await mutation(
    "updateBroadcastList",
    {
      id: {
        type: "Int",
        value: broadcastListInfos.id,
      },
      csv: {
        type: "[String]",
        value: broadcastListInfos.csv,
      },
      bill: {
        type: "[String]",
        value: broadcastListInfos.bill,
      },
      client: {
        type: "[String]",
        value: broadcastListInfos.client,
      },
      report: {
        type: "[String]",
        value: broadcastListInfos.report,
      },
      hairdresser: {
        type: "[String]",
        value: broadcastListInfos.hairdresser,
      },
    },
    `${broadcastListsFields}`
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve<BroadcastList>(body.data.updateBroadcastList)
    : Promise.reject(safeError(body));
};

const fetchInvoicesBySalonId = async (salonId: number) => {
  const res = await query(
    `invoices(salonId: ${salonId}){
            id,
            ident,
            created,
            publicLinkShort,
            publicLink,
        }
        `
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve(buildInvoicesPayload(body.data.invoices || []))
    : Promise.reject(safeError(body));
};

const updatePackageById = async (
  packId: number,
  packInfos: {
    name: string;
    description: string;
    prices: Array<Price>;
    allowPromo: boolean;
    category: {
      id: number;
    };
    status: string;
    partnerRefs: Array<PartnerRef>;
  }
) => {
  const res = await mutation(
    "updatePackage",
    {
      id: {
        type: "Int",
        value: packId,
      },
      name: {
        type: "String",
        value: packInfos.name,
      },
      description: {
        type: "String",
        value: packInfos.description,
      },
      prices: {
        type: "[PriceInput]",
        value: packInfos.prices.map((price) => ({
          duration: price.duration,
          currency: price.currency,
          packageNumber: price.packageNumber,
          partnerRefs: price.partnerRefs,
          price: parseFloat(price.price as any),
          variant: price?.variant && {
            id: price?.variant?.id,
            key: price?.variant?.key,
            name: price?.variant?.name,
          },
        })),
      },
      allowPromo: {
        type: "Boolean",
        value: packInfos.allowPromo,
      },
      mainCategoryId: {
        type: "Int",
        value: packInfos.category.id,
      },
      status: {
        type: "String",
        value: packInfos.status,
      },
      partnerRefs: {
        type: "[PartnerRefInput]",
        value: packInfos.partnerRefs,
      },
    },
    packageFields
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve<Package>(body.data.updatePackage)
    : Promise.reject(safeError(body));
};

const createSalonHairdresser = async (
  salonId: number,
  hairdresserInfos: {
    firstName: string;
    lastName: string;
    image?: string;
    color: string;
    packageIds: Array<number>;
    status: string;
    description: string;
    gender: string;
    partnerRefs: Array<PartnerRef>;
  }
) => {
  const res = await mutation(
    "createHairdresser",
    {
      type: {
        type: "String",
        value: "SALON",
      },
      salonId: {
        type: "Int",
        value: salonId,
      },
      firstName: {
        type: "String",
        value: hairdresserInfos.firstName,
      },
      lastName: {
        type: "String",
        value: hairdresserInfos.lastName,
      },
      description: {
        type: "String",
        value: hairdresserInfos.description,
      },
      gender: {
        type: "String",
        value: hairdresserInfos.gender,
      },
      status: {
        type: "String",
        value: hairdresserInfos.status,
      },
      imageUpload: {
        type: "String",
        value: hairdresserInfos.image,
      },
      color: {
        type: "String",
        value: hairdresserInfos.color.replace("#", ""),
      },
      packageIds: {
        type: "[Int]",
        value: hairdresserInfos.packageIds,
      },
      partnerRefs: {
        type: "[PartnerRefInput]",
        value: hairdresserInfos.partnerRefs,
      },
    },
    hairdresserFields
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve<Hairdresser>({
        ...body.data.createHairdresser,
        color: `#${body.data.createHairdresser.color}`,
      })
    : Promise.reject(safeError(body));
};

const updateHairdresserById = async (
  hairdresserId: number,
  hairdresserInfos: {
    firstName: string;
    lastName: string;
    // image: string;
    color: string;
    packageIds: Array<number>;
    status: string;
    description: string;
    gender: string;
    partnerRefs: Array<PartnerRef>;
  }
) => {
  const res = await mutation(
    "updateHairdresser",
    {
      id: {
        type: "Int",
        value: Number(hairdresserId),
      },
      firstName: {
        type: "String",
        value: hairdresserInfos.firstName,
      },
      lastName: {
        type: "String",
        value: hairdresserInfos.lastName,
      },
      description: {
        type: "String",
        value: hairdresserInfos.description,
      },
      gender: {
        type: "String",
        value: hairdresserInfos.gender,
      },
      status: {
        type: "String",
        value: hairdresserInfos.status,
      },
      color: {
        type: "String",
        value: hairdresserInfos.color.replace("#", ""),
      },
      packageIds: {
        type: "[Int]",
        value: hairdresserInfos.packageIds,
      },
      partnerRefs: {
        type: "[PartnerRefInput]",
        value: hairdresserInfos.partnerRefs,
      },
    },
    hairdresserFields
  );
  const body = await res.json();
  return res.ok
    ? Promise.resolve<Hairdresser>({
        ...body.data.updateHairdresser,
        color: `#${body.data.updateHairdresser.color}`,
      })
    : Promise.reject(safeError(body));
};

/**
 * Map the GraphQl data to
 * the Redux Store data :
 *
 * hairdresser: Array<Hairdresser>
 *      ===>
 *            hairdressersById: { [id: number]: Hairdresser}
 */
const extractHairdressersById = (salonFromGraphQl: any) => {
  return salonFromGraphQl.hairdressers
    ? salonFromGraphQl.hairdressers.reduce(
        (
          hairdressersById: { [id: number]: Hairdresser },
          hairdresser: any
        ) => ({
          ...hairdressersById,
          [hairdresser.id || Number(hairdresser.stringId)]: {
            id: hairdresser.id,
            stringId: hairdresser.stringId || "",
            firstName: hairdresser.firstName,
            lastName: hairdresser.lastName,
            image: hairdresser.image,
            color:
              hairdresser?.color?.indexOf("#") > -1
                ? hairdresser.color
                : `#${hairdresser.color}`,
            salonId: salonFromGraphQl.id,
            packageIds: [
              ...(hairdresser.serviceIds &&
              hairdresser.serviceIds.length !== 0 &&
              salonFromGraphQl.isOneCatalog
                ? hairdresser.serviceIds.map((id: string) => Number(id))
                : hairdresser.packageIds),
            ],
            status: hairdresser.status,
            description: hairdresser.description,
            gender: hairdresser.gender,
            partnerRefs: hairdresser.partnerRefs,
            serviceIds: hairdresser.serviceIds || [],
            imageUrl: hairdresser.imageUrl,
          },
        }),
        {}
      )
    : {};
};

/**
 * Map the GraphQl data to
 * the Redux Store data
 */
const buildSalonPayload = async (
  fetchSalonsResult: any
): Promise<SalonPayload> => {
  const salonWithOptions = (await Promise.all(
    fetchSalonsResult.map(async (salon: any) => {
      const refs = salon.partnerRefs || [];
      const flexyCompanyCode =
        refs.find((ref: any) => ref && ref.name === "flexy")?.id || undefined;
      if (!flexyCompanyCode || flexyCompanyCode === "") {
        return {
          ...salon,
          options: {
            autoCalendar: false,
            oneCatalogV2: false,
          },
        };
      }

      const isAutoCalendar = await hasSalonOption(
        "autoCalendar",
        flexyCompanyCode
      );
      const isOneCatalogV2 = await hasSalonOption(
        "oneCatalogV2",
        flexyCompanyCode
      );
      return {
        ...salon,
        options: {
          autoCalendar: isAutoCalendar,
          oneCatalogV2: isOneCatalogV2,
        },
      };
    })
  )) as any;
  const salonById: Record<number, Salon> = salonWithOptions.reduce(
    (salons: Record<number, Salon>, salonFromGraphQl: any) => ({
      ...salons,
      [salonFromGraphQl.id]: {
        // Properties copied directly from GraphQl result
        id: salonFromGraphQl.id,
        status: salonFromGraphQl.status,
        slug: salonFromGraphQl.slug,
        name: salonFromGraphQl.name,
        description: salonFromGraphQl.description,
        email: salonFromGraphQl.email,
        phone: salonFromGraphQl.phone,
        mobile: salonFromGraphQl.mobile,
        logo: salonFromGraphQl.logo,
        background: salonFromGraphQl.background,
        backgrounds: salonFromGraphQl.backgrounds,
        adwordsCampaignId: salonFromGraphQl.adwordsCampaignId,
        companyName: salonFromGraphQl.companyName,
        billingFirstName: salonFromGraphQl.billingFirstName,
        billingLastName: salonFromGraphQl.billingLastName,
        billingNationality: salonFromGraphQl.billingNationality,
        billingCountry: salonFromGraphQl.billingCountry,
        recommendation: salonFromGraphQl.recommendation,
        carouselImages: salonFromGraphQl.carouselImages,
        access: salonFromGraphQl.access || [],
        siret: salonFromGraphQl.siret,
        iban: salonFromGraphQl.iban,
        tvaNumber: salonFromGraphQl.tvaNumber,
        address: salonFromGraphQl.address,
        billingAddress: salonFromGraphQl.billingAddress,
        geoLocation: salonFromGraphQl.geoLocation,
        packages: salonFromGraphQl.packages,
        thirdPartyPaymentId: salonFromGraphQl.thirdPartyPaymentId,
        partnerRefs: salonFromGraphQl.partnerRefs,
        waitingApprovalRequests: [],
        allowPaymentInSalon: salonFromGraphQl.allowPaymentInSalon,
        isOneCatalog: salonFromGraphQl.isOneCatalog,
        // Extra Properties not exact copies of GraphQl result
        invoices: [],
        hairdresserById: extractHairdressersById(salonFromGraphQl),
        options: salonFromGraphQl.options,
      },
    }),
    {}
  );
  return {
    salonById,
  };
};

/**
 * Map the GraphQl field names to
 * the Redux Store field names :
 *
 * GraphQL          ||     Redux Store
 * ident            =>      slug
 * publicLinkShort  =>      url
 * publicLink       =>      pdfUrl
 *
 */
const buildInvoicesPayload = (
  invoicesFromGraphQl: Array<any>
): Array<Invoice> => {
  return invoicesFromGraphQl.map((invoiceFromGraphQl) => {
    const invoice: Invoice = {
      id: invoiceFromGraphQl.id,
      createdAt: invoiceFromGraphQl.created,
      slug: invoiceFromGraphQl.ident,
      url: invoiceFromGraphQl.publicLinkShort,
      pdfUrl: invoiceFromGraphQl.publicLink,
    };
    return invoice;
  });
};

/**
 * Get the currently selected Salon from Redux Store
 * @param {SalonState} salonState - The Redux Salon State
 * @returns {Salon | null} The currently selected Salon or null
 */
const getSelectedSalon = (salonState: SalonState): Salon | null => {
  if (
    !salonState.payload ||
    !salonState.payload.salonById ||
    !salonState.payload.selectedSalonId
  )
    return null;
  return salonState.payload.salonById[salonState.payload.selectedSalonId];
};

/**
 * Get the currently selected Salon Hairdressers Object by Id key
 * @param {SalonState} salonState - The Redux Salon State
 * @returns {{ [id: number]: Hairdresser }} Currently selected Salon Hairdressers  Object by Id key (or empty object if the currently selected salon is null)
 */
const getHairdressersById = (
  salonState: SalonState
): {
  [id: number]: Hairdresser;
} => {
  const selectedSalon = getSelectedSalon(salonState);
  return selectedSalon && selectedSalon.hairdresserById
    ? selectedSalon.hairdresserById
    : {};
};

/**
 * Get the currently selected Salon Hairdressers list
 * @param {SalonState} salonState - The Redux Salon State
 * @returns {Array<Hairdresser>} Currently selected Salon Hairdressers list (or empty array if the currently selected salon is null)
 */
const getHairdressers = (salonState: SalonState): Array<Hairdresser> => {
  const hairdresserById = getHairdressersById(salonState);
  return Object.values(hairdresserById);
};

export {
  fetchSalons,
  fetchAllSalonsBasicInfos,
  fetchBroadcastListsBySalonIds,
  updateSalonById,
  updateBroadcastList,
  fetchInvoicesBySalonId,
  getSalonCache,
  getSelectedSalon,
  getHairdressers,
  updatePackageById,
  createSalonHairdresser,
  updateHairdresserById,
  getHairdressersById,
  fetchStripeCapabilitiesRequest,
  generateStripeForm,
};
