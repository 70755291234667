import React, { Suspense } from "react";
import { Redirect } from "react-router";
import { parseRoute } from "utils/route";
import { routeList } from "constants/Routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import Mobile from "components/ResponsiveLayout/Mobile";
import Desktop from "components/ResponsiveLayout/Desktop";
import useCurrentSalon from "hooks/useCurrentSalon";

// Lazy Loaded because we use react-pdj in this component
// which represents 45% of the bundle
const InvoicePreviewPageTemplate = React.lazy(() =>
  import("components/payment/InvoicePreviewPage")
);

interface InvoicePreviewPageProps {
  match: {
    params: {
      invoiceSlug: string;
    };
  };
}

const pdfUrlToDlPdfUrl = (pdfUrl: string): string => {
  return pdfUrl.replace(
    "https://sellsy.com/?_f=file&",
    "https://file.sellsy.com/?"
  );
};

function InvoicePreviewPage({ match }: InvoicePreviewPageProps) {
  const previousRoute = parseRoute(routeList.PAYMENT_INVOICES_PAGE);
  const invoiceSlug = match.params.invoiceSlug;
  const { salon } = useCurrentSalon();
  const invoice = salon.invoices.find(invoice => invoice.slug === invoiceSlug);

  const fileUrl = (invoice && pdfUrlToDlPdfUrl(invoice.pdfUrl)) || "";
  const invoicePreview = {
    slug: (invoice && invoice.slug) || "",
    fileUrl
  };
  return (
    (invoice && (
      <>
        <Mobile>
          <Suspense
            fallback={<CircularProgress className="ui-loader-centered" />}
          >
            <InvoicePreviewPageTemplate
              previousRoute={previousRoute}
              invoicePreview={invoicePreview}
            />
          </Suspense>
        </Mobile>
        <Desktop>
          <DownloadFile
            name="Facture_LeCiseau"
            url={fileUrl}
            thenRedirectTo={previousRoute}
          />
        </Desktop>
      </>
    )) || <Redirect to={previousRoute} />
  );
}

function saveFile(name: string, url: string) {
  // create a temporary <a> elem which we'll use to download the image
  const link = document.createElement("a");
  link.setAttribute("download", name);
  link.setAttribute("href", url);
  link.click();

  // clean up
  link.remove();
}

interface DownloadFileProps {
  name: string;
  url: string;
  thenRedirectTo: string;
}

function DownloadFile({ name, url, thenRedirectTo }: DownloadFileProps) {
  saveFile(name, url);
  return <Redirect to={thenRedirectTo} />;
}

export default InvoicePreviewPage;
