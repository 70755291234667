import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { fetchSalons } from "actions/SalonActions";
import { fetchUnavailabilitiesByHairdresserIds } from "actions/UnavailabilityActions";
import { fetchSlotsByHairdresserIds } from "actions/SlotActions";
import { fetchBookingsBySalonId } from "actions/BookingActions";
import * as CALENDAR from "constants/Calendar";
import { STATUSES } from "constants/Account";
import AgendaPage, {
  AGENDA_EVENT_KINDS,
} from "components/agenda/AgendaPage/AgendaPage";
import {
  ICreatingEventMeta,
  ISlotEventMeta,
  IBookingEventMeta,
} from "components/agenda/EventRenderer/EventRenderer";
import { CalendarEvent } from "components/Calendar";
import ResponsiveModalContainer from "components/modal/Container/ResponsiveContainer";
import EventForm from "components/agenda/EventForm";
import { AgendaFilters } from "hooks/useAgendaFilterSelector";
import useCurrentSalon from "hooks/useCurrentSalon";
import { AppState } from "reducers";
import { Slot } from "reducers/slot";
import { CommonRange } from "@leciseau/schedule-manager";
import {
  hairdresserToResource,
  slotShouldBeDisplayed,
  slotToEvent,
  bookingShouldBeDisplayed,
  bookingToEvent,
  unavailabilityToEvent,
  enabledHairdresserFirst,
  unavailabilityShouldBeDisplayed,
  lastMinutesToCommonRanges,
  lmToEvents,
  commonRangeToSlot,
} from "utils/agenda";
import {
  removeEachDay,
  commonRangeToCalendarEvent,
  eventToCommonRange,
  splitRangesWithLastMinutes,
  mergeCommonRanges,
} from "utils/agendaDisplay";

const REFETCH_INTERVAL = 60_000;

type TCalendarView = keyof typeof CALENDAR.VIEWS;

const appStateSelector = (state: AppState) => state;

function AgendaPageContainer() {
  const {
    auth: authState,
    slot: slotState,
    booking: bookingState,
    salon: { loading: salonLoading },
  } = useSelector(appStateSelector);

  const dispatch = useDispatch();

  const {
    hairdressers,
    salon,
    slots,
    bookings,
    unavailabilities,
    fetchCurrentAvailabilities,
    availabilitiesByHairdresserId,
    companyCode,
    fetchCurrentLastMinutes,
    lastMinutes,
  } = useCurrentSalon();

  const allHairdressers = hairdressers.sort(enabledHairdresserFirst);
  const allHairdresserIds = allHairdressers.map((h) => h.id).filter(Boolean);
  const allHairdresserStringIds = allHairdressers
    .map((h) =>
      h.stringId && h.stringId !== ""
        ? `${companyCode}|${h.stringId}`
        : undefined
    )
    .filter(Boolean) as Array<string>;
  const enabledHairdresserIds = allHairdressers
    .filter((h) => h.status === STATUSES.ENABLED)
    .map((h) => h.id || Number(h.stringId));
  const [date, setDate] = useState(moment().startOf("day").toISOString());

  const [mode, setMode] = useState<TCalendarView>(CALENDAR.VIEWS.DAY);
  const [slotFormOpened, setSlotFormOpened] = useState(false);
  const [eventToEdit, setEventToEdit] = useState<
    | CalendarEvent<ICreatingEventMeta | ISlotEventMeta | IBookingEventMeta>
    | undefined
  >(undefined);
  const [filters, setFilters] = useState<AgendaFilters>({
    hairdresserIds: enabledHairdresserIds,
    showBookings: true,
    showFreeSlots: true,
    showUnavailabilities: true,
  });

  useEffect(() => {
    setFilters({
      hairdresserIds: enabledHairdresserIds,
      showBookings: true,
      showFreeSlots: true,
      showUnavailabilities: true,
    });
    fetchCurrentAvailabilities();
    fetchCurrentLastMinutes();
  }, [salon.id]);

  const fetchAllSlots = (date: string, mode: TCalendarView) => {
    if (
      allHairdresserIds.length === 0 &&
      allHairdresserStringIds.length === 0
    ) {
      return;
    }
    dispatch(
      fetchSlotsByHairdresserIds(
        allHairdresserIds,
        allHairdresserStringIds || [],
        date,
        { range: mode }
      )
    );
  };

  const fetchAllUnavailabilities = (date: string, mode: TCalendarView) => {
    if (
      allHairdresserIds.length === 0 &&
      allHairdresserStringIds.length === 0
    ) {
      return;
    }
    dispatch(
      fetchUnavailabilitiesByHairdresserIds(
        allHairdresserIds,
        allHairdresserStringIds,
        date,
        {
          range: mode,
        }
      )
    );
  };

  const fetchAllBookings = (date: string, mode: TCalendarView) => {
    dispatch(
      fetchBookingsBySalonId(salon.id, moment(date).toISOString(), {
        range: mode,
      })
    );
  };

  const fetchAllDatas = useCallback(() => {
    fetchAllSlots(date, mode);
    fetchAllUnavailabilities(date, mode);
    fetchAllBookings(date, mode);
    // Do not pass date to this, it is synchronized
    // from today only
    fetchCurrentAvailabilities();
    fetchCurrentLastMinutes();
  }, [date, mode, salon.id]);

  useEffect(() => {
    const args = salon.id ? { ids: [salon.id] } : { auth: authState.payload };
    dispatch(fetchSalons(args));
  }, []);

  const updateDate = (newDate: string) => {
    if (newDate.length === 0) {
      return;
    }
    const momentDate = moment(newDate);
    if (
      (momentDate as any)._f === "YYYY-MM" &&
      momentDate.month() === moment(date).month() &&
      momentDate.year() === moment(date).year()
    ) {
      return; // prevent ios monthpicker to change date when opening
    }
    setDate(newDate);
  };

  useEffect(() => {
    fetchAllDatas();

    const interval = setInterval(() => {
      fetchAllDatas();
    }, REFETCH_INTERVAL);

    return () => clearInterval(interval);
  }, [fetchAllDatas]);

  const editEvent = (
    event?: CalendarEvent<
      ICreatingEventMeta | ISlotEventMeta | IBookingEventMeta
    >
  ) => {
    setEventToEdit(event);
    setSlotFormOpened(true);
  };

  const [onSlotFormUpdate, setOnSlotFormUpdate] = useState<
    { action: (isOpen: boolean) => void }[]
  >([]);
  const createSlot = (partialSlot?: Omit<Slot, "id">) => {
    const event: CalendarEvent<ICreatingEventMeta> | undefined = partialSlot
      ? {
          id: AGENDA_EVENT_KINDS.CREATING,
          meta: {
            kind: AGENDA_EVENT_KINDS.CREATING,
            partialSlot,
          },
          resourceId: partialSlot.hairdresserId || 0,
          hairdresserStringId: partialSlot.hairdresserStringId || "",
          start: moment(partialSlot.range.start),
          end: moment(partialSlot.range.end),
        }
      : undefined;
    editEvent(event);
    return new Promise((resolve) => {
      // Hack to detect the closing of the slot from modal ...
      setOnSlotFormUpdate((callbacks) => {
        return [
          {
            action: (isOpen) => {
              if (!isOpen) {
                resolve();
              }
            },
          },
          ...callbacks,
        ];
      });
    });
  };

  useEffect(() => {
    onSlotFormUpdate.forEach((callback) => callback.action(slotFormOpened));
  }, [slotFormOpened, onSlotFormUpdate]);

  const closeSlotForm = () => {
    setSlotFormOpened(false);
    setEventToEdit(undefined);
  };

  const refetchSlots = () => {
    fetchAllSlots(date, mode);
  };

  const filteredHairdressers = allHairdressers.filter(
    (h) =>
      (filters.hairdresserIds as Array<number>).includes(h.id) ||
      (filters.hairdresserIds as Array<number>).includes(Number(h.stringId))
  );
  const resources = filteredHairdressers.map((hairdresser) =>
    hairdresserToResource(
      hairdresser,
      companyCode,
      salon?.options?.oneCatalogV2
    )
  );

  const avails = removeEachDay(
    [
      ...(Object.values(availabilitiesByHairdresserId)
        .reduce((acc, item) => [...acc, ...item], [])
        .map((val: any) => eventToCommonRange(val)) || []),
    ],
    [
      ...(slots
        .filter(slotShouldBeDisplayed(filters))
        .map(eventToCommonRange) || []),
    ]
  );

  const lastMinutesRanges = lastMinutesToCommonRanges(
    lastMinutes.filter(({ status }) => status === "ENABLED"),
    resources
  );

  const slotResult = splitRangesWithLastMinutes(
    slots.filter(slotShouldBeDisplayed(filters)) as CommonRange[],
    lastMinutesRanges
  );
  const newSlots = slotResult.newRanges;
  const lmSlots = slotResult.realLastMinuteRanges;

  const availResult = splitRangesWithLastMinutes(
    avails.filter(slotShouldBeDisplayed(filters)),
    lastMinutesRanges
  );
  const newAvails = availResult.newRanges;
  const lmAvails = availResult.realLastMinuteRanges;

  const allLms = [...lmSlots, ...lmAvails];

  const lastMinuteRanges = mergeCommonRanges(allLms);

  const events = [
    ...newAvails.map(commonRangeToCalendarEvent),
    ...newSlots.map(commonRangeToSlot),
    ...lastMinuteRanges.map(lmToEvents),
    ...bookings.filter(bookingShouldBeDisplayed(filters)).map(bookingToEvent),
    ...unavailabilities
      .filter(unavailabilityShouldBeDisplayed(filters))
      .map(unavailabilityToEvent),
  ];

  return (
    <React.Fragment>
      <AgendaPage
        resources={resources}
        events={events}
        filters={filters}
        mode={mode}
        onSelectMode={setMode}
        createSlot={createSlot}
        date={moment(date)}
        updateDate={updateDate}
        updateFilters={setFilters}
        editEvent={editEvent}
        isLoading={salonLoading || slotState.loading || bookingState.loading}
      />
      <ResponsiveModalContainer
        fullScreenOnMobile
        open={slotFormOpened}
        direction="up"
      >
        <EventForm
          eventToEdit={eventToEdit}
          date={moment(date)}
          onClose={closeSlotForm}
          refetchSlots={refetchSlots}
        />
      </ResponsiveModalContainer>
    </React.Fragment>
  );
}

export default AgendaPageContainer;
