import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useClickAway from "react-use/lib/useClickAway";
import classnames from "classnames";
import "./menu-item.desktop.scss";
import { SublinksConditions } from "constants/Menu";
import useCurrentSalon from "hooks/useCurrentSalon";

interface IBasicDestkopMenuItem {
  title: string;
  icon: React.SFC;
}

// Need to Better split the 2 interfaces ...
interface IDesktopMenuItemSimple extends IBasicDestkopMenuItem {
  link: string;
  sublinks?: undefined;
  onAction?(action: string): void;
  extraClassName?(item: ISubLink): string | undefined;
}

export type ISubLink =
  | {
      title: string;
      link: string;
      conditions?(conditions: SublinksConditions): boolean;
    }
  | {
      title: string;
      action: string;
      conditions?(conditions: SublinksConditions): boolean;
    };

interface IDesktopMenuItemWithSubLinks extends IBasicDestkopMenuItem {
  link?: undefined;
  sublinks: Array<ISubLink>;
  onAction?(action: string): void;
  extraClassName?(item: ISubLink): string | undefined;
}

export type IDesktopMenuItem =
  | IDesktopMenuItemSimple
  | IDesktopMenuItemWithSubLinks;

function DesktopMenuItem({
  icon,
  title,
  link,
  sublinks,
  onAction,
  extraClassName,
}: IDesktopMenuItem) {
  const location = useLocation();
  const linkSelected = link ? location.pathname === link : false;
  const sublinkSelected = sublinks
    ? sublinks.some(
        (sublink) => "link" in sublink && location.pathname === sublink.link
      )
    : false;

  const [isSublinksOpened, setIsSublinksOpened] = useState(false);
  const closeSublinks = () => setIsSublinksOpened(false);

  const { salon } = useCurrentSalon();
  const linksConditions = {
    isFlexySalon: !!salon?.partnerRefs?.find((ref) => ref.name === "flexy"),
    autoCalendar: salon.options?.autoCalendar || false,
  };

  const itemContainerRef = useRef(null);
  useClickAway(itemContainerRef, closeSublinks);
  return link ? (
    <Link to={link}>
      <Item selected={linkSelected} icon={icon} title={title} />
    </Link>
  ) : (
    <div className="menu-item-with-sublinks" ref={itemContainerRef}>
      <Item
        icon={icon}
        title={title}
        selected={sublinkSelected}
        onClick={() => setIsSublinksOpened((isOpened) => !isOpened)}
      />
      {isSublinksOpened && (
        <div className="menu-item-with-sublinks__sublinks">
          {(sublinks || [])
            .filter(
              (link) => !link.conditions || link.conditions(linksConditions)
            )
            .map((sublink) =>
              "link" in sublink ? (
                <Link
                  key={sublink.title}
                  to={sublink.link}
                  onClick={closeSublinks}
                  className={classnames(
                    extraClassName && extraClassName(sublink)
                  )}
                >
                  {sublink.title}
                </Link>
              ) : (
                <div
                  key={sublink.title}
                  onClick={() => onAction && onAction(sublink.action)}
                  className={classnames(
                    extraClassName && extraClassName(sublink)
                  )}
                >
                  {sublink.title}
                </div>
              )
            )}
        </div>
      )}
    </div>
  );
}

const Item = ({
  icon: Icon,
  title,
  selected,
  onClick,
}: {
  selected: boolean;
  onClick?(): void;
} & IBasicDestkopMenuItem) => (
  <div className={classnames("menu-item", { selected })} onClick={onClick}>
    {Icon && <Icon />}
    <span>{title}</span>
  </div>
);

export default DesktopMenuItem;
